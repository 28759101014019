import React, { useEffect, useState } from "react"

import view from "./fullslider.module.scss"

export default function FullSlider(props) {
  const { imageList, index, unavailableImg } = props
  const { closeSlider } = props

  const [jndex, setJndex] = useState(0)

  useEffect(() => {
    setJndex(index)
  }, [index])

  const goLeft = (e, slide) => {
    e.stopPropagation()
    const idx = slide - 1 < 0 ? imageList.length - 1 : slide - 1;
    setJndex(idx)
  }

  const goRite = (e, slide) => {
    e.stopPropagation()
    const idx = slide + 1 > imageList.length - 1 ? 0 : slide + 1;
    setJndex(idx)
  }

  const handleImgError = (event) => {
    event.target.onerror = null;
    event.target.src = unavailableImg
  }

  return (
    <div className={view["fullscreen"]} onClick={closeSlider}>
      <div className={view["img-wrap"]}>
        <div className={view["fs-close"]} onClick={closeSlider}>
          &#215;
        </div>
        <div className={view["arrow"]} style={{ left: 0 }}
          onClick={(e) => goLeft(e, jndex)}>
          <p>&#5176;</p>
        </div>

        <img className={view["fs-image"]}
          onError={(e) => handleImgError(e)}
          src={imageList[jndex] != undefined ? imageList[jndex].PhotoUrl : unavailableImg} />

        <div className={view["arrow"]} style={{ right: 0 }}
          onClick={(e) => goRite(e, jndex)}>
          <p>&#5171;</p>
        </div>
      </div>
    </div>
  )
}